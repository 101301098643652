@import "~bootstrap/dist/css/bootstrap.css";
@import 'ngx-toastr/toastr';
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.np-loader{
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}


.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #ec6608 !important;
}
.mat-progress-spinner{
margin-left: -50px;
margin-top: -50px;
position: absolute;
    top: 50%;
    left: 50%;
}



.card-header {
  color: #00FF00;
  background-color: #fff;
}

.yellow-standerd {
  color: #00A844;
}

.navItem {
  width: calc(100% - 280px);
}

.btnposition {
  bottom: 0;
  // position: absolute;
  left: 1%;
  right: 1%;
}

@media screen and (max-width: 959px) {
  .navItem {
    width: 100%;
  }
}

.delete-records-style {
  background-color: #ececec !important;
  opacity: 0.5;
}

.addBtn {
  box-shadow: 0px 10px 15px rgba(135, 135, 135, 0.2);
  border-radius: 10px !important;
  width: auto;
  // margin-right: 2%;
}

.btn-clr {
  background-color:  #0DB00D!important
}

.txt_over_flow {
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 15px;
  position: relative;
}
.heading{
font-style: normal;
font-weight: 600;
font-family: 'Poppins', sans-serif;
line-height: 1.8 !important;
color: #000000;
}
.createHeading{
  font-style: normal;
font-weight: 600;
font-family: 'Poppins', sans-serif;
line-height: 1.5 !important;
}
.viewHeading{
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 33px ;
font-family: 'Poppins', sans-serif !important;
}
.fieldName{
  font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
}
.white-icon {
  color: white;
}
/* Note: If you're using an SVG icon, you should make the class target the `<svg>` element */
.white-icon svg {
  color: white;
}

.listFont {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #484848;
}

.headings{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #00FF00;
}



.hintStyle {
  color: red;
}



mat-pseudo-checkbox.mat-pseudo-checkbox.mat-mdc-option-pseudo-checkbox.ng-star-inserted.mat-pseudo-checkbox-checked {
  background: #00FF00 !important;
}

.theme-amber.light .mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #00FF00 !important
}

// .mat-mdc-tooltip-trigger .fuse-vertical-navigation-item .ng-star-inserted .fuse-vertical-navigation-item-active {
//   color: #00FF00 !important
// }

// .fuse-vertical-navigation-item-title{
//   color: #00FF00 !important
// }

.fuse-vertical-navigation-item-active{
  color: #00FF00 !important
}

.mat-calendar-body-cell-content {
  &.mat-focus-indicator {
    &:hover {
      background-color: #ACFFC3 !important;
    }
  }

  &.mat-calendar-body-today {
    background-color: #FFFFFF !important;

    &:hover {
      background-color: #ACFFC3 !important;
    }
  }

  &.mat-calendar-body-selected {
    background-color: #00FF00 !important;

    &:hover {
      background-color: #ACFFC3 !important;
    }
  }
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: #00FF00 !important;
}

.theme-amber.light .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: #00FF00 !important;
}

.theme-amber.light .mdc-list-item--selected .mdc-list-item__primary-text, .theme-amber.light .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #00FF00 !important
}

.theme-amber.light .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #00FF00 !important;
}

.theme-amber.light .mat-toolbar.mat-primary {
  background: #00FF00 !important;
  color: #78350f;
}

.theme-amber.light .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #00FF00 !important; //progress bar
  --mdc-linear-progress-track-color:  #ACFFC3 !important; //background color
}

.bottomBtns{
  position: relative;
  bottom: 0 !important;
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
}
